<template>
  <div class="post">
    <goods-loading-table></goods-loading-table>
  </div>
</template>

<script>
import GoodsLoadingTable from "@/components/GoodsLoadingTable.vue";
export default {
  components: {
    "goods-loading-table": GoodsLoadingTable
  }
};
</script>
