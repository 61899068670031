export default {
  data() {
    return {
      pagination: {
        limit: 20,
        offset: 0,
        currentPage: 1,
        totalCount: 0
      },
      sort: {
        by: "",
        isDesc: false
      }
    };
  },
  computed: {
    paginationParams() {
      return {
        offset: this.pagination.limit * (this.pagination.currentPage - 1),
        limit: this.pagination.limit,
        sortBy: this.sort.by,
        isSortDesc: this.sort.isDesc
      };
    }
  },
  methods: {
    onSortChanged({ sortBy, sortDesc }, loadDataCallback) {
      this.sort.by = sortBy;
      this.sort.isDesc = sortDesc;

      this.onChangeCurrentPage(1, loadDataCallback);
    },
    onChangeCurrentPage(page, loadDataCallback) {
      this.pagination.currentPage = page;

      loadDataCallback();
    },
    onChangeFilterValue(filters, property, value, loadDataCallback) {
      if (filters.hasOwnProperty(property)) {
        if (property == "type") {
          filters["type"] = value.type;
          filters["issueCategoryId"] = value.categoryId || null;
        } else {
          filters[property] = value;
        }

        this.onChangeCurrentPage(1, loadDataCallback);
      }
    }
  }
};
