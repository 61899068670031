<style>
.vue-tags-input :only-child {
  border-color: #ced4da;
  border-radius: 0.25rem;
}
.gray-container {
  background-color: #eceeef;
  border-radius: 5px;
}
.ti-autocomplete {
  position: relative !important;
  max-height: 6rem;
  overflow: auto;
}
</style>

<template>
  <div>
    <b-container fluid>
      <b-row class="mb-2">
        <b-col>
          <v-select
            v-model="selectedGroup"
            :options="groupOptions"
            :reduce="group => group.title"
            label="title"
            @input="getGoodsByGroup"
            :placeholder="$t('goodsLoadingGroup.selectGroup')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <v-select
            v-model="selectedProduct"
            :options="options"
            :getOptionLabel="x => `${x.title} - ${x.code}`"
            :reduce="product => product.id"
            :placeholder="$t('goodsLoadingGroup.selectProduct')"
            label="title"
            @input="getAugment"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-col>
      </b-row>
      <b-container fluid class="gray-container mb-2 px-4">
        <b-row>
          <b-col>
            <b-row v-if="selectedProduct">
              <label v-if="selectedProductHasSerialNumber">
                {{ $t("goodsLoadingGroup.productAmountInStock", { amount: stockProductAmount }) }}
              </label>
            </b-row>
            <b-row v-if="selectedProduct">
              <label>{{ $t("goodsLoadingGroup.productAmountInVehicle", { amount: vehicleLoadedAmount }) }}</label>
            </b-row>
            <b-row v-if="selectedProduct">
              <label>{{ $t("goodsLoadingGroup.productTotalAmount", { amount: totalAmount }) }}</label>
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-row v-if="selectedProduct">
              <label v-if="parseInt(productMissingAmount) >= 0 || productMissingAmount == ''">
                {{ $t("goodsLoadingGroup.productMissingAmount", { amount: productMissingAmount }) }}
              </label>
              <label v-else>
                {{ $t("goodsLoadingGroup.productMissingAmountWithExcess", { amount: productMissingAmount }) }}
              </label>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="gray-container mb-2 p-3" fluid>
        <b-row>
          <b-col>
            <b-input-group>
              <b-form-checkbox
                switch
                :checked="filter.showVehiclesWithGoodsShortage"
                @input="onChangeFilterValue(filter, 'showVehiclesWithGoodsShortage', $event, getAugment)"
              >
                {{ $t("goodsLoadingGroup.showVehiclesWithGoodsShortage") }}
              </b-form-checkbox>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group>
              <b-form-checkbox
                switch
                :checked="filter.showVehiclesWithCriticalGoodsShortage"
                @input="onChangeFilterValue(filter, 'showVehiclesWithCriticalGoodsShortage', $event, getAugment)"
              >
                {{ $t("goodsLoadingGroup.showVehiclesWithCriticalGoodsShortage") }}
              </b-form-checkbox>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group>
              <b-form-checkbox
                switch
                :checked="filter.showVehiclesWithExtraGoods"
                @input="onChangeFilterValue(filter, 'showVehiclesWithExtraGoods', $event, getAugment)"
              >
                {{ $t("goodsLoadingGroup.showVehiclesWithExtraGoods") }}
              </b-form-checkbox>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group>
              <b-form-checkbox
                switch
                :checked="filter.showVehiclesWithoutGoods"
                @input="onChangeFilterValue(filter, 'showVehiclesWithoutGoods', $event, getAugment)"
              >
                {{ $t("goodsLoadingGroup.showVehiclesWithoutGoods") }}
              </b-form-checkbox>
            </b-input-group>
          </b-col>
        </b-row>
      </b-container>
      <div>
        <b-pagination
          :value="pagination.currentPage"
          :total-rows="pagination.totalCount"
          :per-page="pagination.limit"
          @input="onChangeCurrentPage($event, getAugment)"
        ></b-pagination>
        <b-table
          :fields="fields"
          :items="items"
          responsive
          no-local-sorting
          @sort-changed="onSortChanged($event, getAugment)"
        >
          <template v-slot:top-row>
            <td>
              <b-input
                :value="filter.registrationNumber"
                :placeholder="$t('goodsLoadingGroup.vehicleNumber')"
                @change="onChangeFilterValue(filter, 'registrationNumber', $event, getAugment)"
              ></b-input>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <b-input
                :value="filter.manager"
                :placeholder="$t('goodsLoadingGroup.user')"
                @change="onChangeFilterValue(filter, 'manager', $event, getAugment)"
              ></b-input>
            </td>
            <td>
              <b-datepicker
                value-as-date
                reset-button
                :value="filter.loadDate"
                :locale="$i18n.locale"
                :label-reset-button="$t('datepicker.reset-btn')"
                @input="onChangeFilterValue(filter, 'loadDate', $event, getAugment)"
                placeholder=""
                label-no-date-selected=""
                boundary="window"
              ></b-datepicker>
            </td>
            <td></td>
            <td></td>
          </template>
          <template v-slot:cell(calculated)="row">
            <b-row>
              <b-col v-if="row.item.loadedQuantity < row.item.minQuantity">
                {{ $t("goodsLoadingGroup.missingAmount") }} {{ row.item.minQuantity - row.item.loadedQuantity }}
              </b-col>
              <b-col v-if="row.item.loadedQuantity > row.item.optimalQuantity">
                {{ $t("goodsLoadingGroup.excessAmount") }}
                {{ row.item.loadedQuantity - row.item.optimalQuantity }}
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(title)="row">
            <b-link @click="onOpenEditGroupModal(row.value[$i18n.locale])">{{ row.value[$i18n.locale] }}</b-link>
          </template>
          <template v-slot:cell(additionalQuantity)="row">
            <b-input
              v-model.lazy.number="row.item.additionalQuantity"
              type="number"
              @input="changeAdditionalQuantity(row.item)"
            />
          </template>
          <template v-slot:cell(serialNumbers)="row">
            <div v-if="products.find(x => x.id == selectedProduct).hasSerialNumber">
              <vue-tags-input
                v-model="tag[row.item.vehicleId]"
                :tags="tags(row.item)"
                @tags-changed="changeTags(row.item, arguments[0])"
                :autocomplete-items="autocompleteItems(row.item)"
                :placeholder="$t('goodsLoadingGroup.addSerialNumberPlaceholder')"
                :add-on-blur="false"
              />
            </div>
          </template>
          <template v-slot:cell(loadedQuantity)="row">
            <b-input
              v-model.lazy.number="row.item.loadedQuantity"
              type="number"
              @input="changeLoadedQuantity(row.item)"
            />
          </template>
          <template v-slot:cell(loadDate)="row">{{ $d(new Date(row.value)) }}</template>
          <template v-slot:cell(equip)="row">
            <b-button @click="equipCar(row.item)">{{ $t("goodsLoadingGroup.loadButton") }}</b-button>
          </template>
        </b-table>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";
import api from "@/services/api.js";

import paginationMixin from "@/mixins/pagination-mixin.js";

export default {
  mixins: [paginationMixin],
  components: {
    VueTagsInput
  },
  data() {
    return {
      items: [],
      groups: [],
      products: [],
      selectedProduct: "",
      selectedProductHasSerialNumber: false,
      selectedGroup: "",
      filter: {
        showVehiclesWithoutGoods: false,
        showVehiclesWithGoodsShortage: false,
        showVehiclesWithCriticalGoodsShortage: false,
        showVehiclesWithExtraGoods: false,
        registrationNumber: "",
        manager: "",
        loadDate: ""
      },
      vehicleLoadedAmount: "",
      stockProductAmount: "",
      productMissingAmount: "",
      totalAmount: "",
      initialLoadedValues: {},
      availableSerialNumbers: [],
      tag: []
    };
  },
  computed: {
    options() {
      return this.products.map(x => {
        return { id: x.id, title: x.title[this.$i18n.locale], code: x.code };
      });
    },
    groupOptions() {
      return this.groups.map(x => {
        return { title: x.title[this.$i18n.locale] };
      });
    },
    fields() {
      return [
        { key: "registrationNumber", label: this.$t("goodsLoadingGroup.vehicleNumber"), sortable: true },
        { key: "calculated", label: this.$t("goodsLoadingGroup.calculatedQuantity") },
        { key: "minQuantity", label: this.$t("goodsLoadingGroup.minimalQuantity"), sortable: true },
        { key: "optimalQuantity", label: this.$t("goodsLoadingGroup.optimalQuantity") },
        { key: "serialNumbers", label: this.$t("goodsLoadingGroup.serialNumbers") },
        { key: "additionalQuantity", label: this.$t("goodsLoadingGroup.additionalQuantity") },
        { key: "loadedQuantity", label: this.$t("goodsLoadingGroup.loadedQuantity") },
        { key: "manager", label: this.$t("goodsLoadingGroup.user") },
        { key: "loadDate", label: this.$t("goodsLoadingGroup.date") },
        { key: "equip", label: "" },
        { key: "unequip", label: "" }
      ];
    }
  },
  methods: {
    ...mapActions([
      "goods/getBaseInfoList",
      "goods/getGoodsByGroup",
      "goodsGroup/getGroupsInfo",
      "goods/getAvailableSerialNumbers"
    ]),
    getVehicleLoadedAmount() {
      let vehicleLoadedAmount = 0;
      if (this.selectedProduct == null) return 0;

      for (let i = 0; i < this.items.length; i++) {
        vehicleLoadedAmount += parseInt(this.items[i].loadedQuantity);
      }
      return vehicleLoadedAmount;
    },
    getStockProductAmount() {
      if (this.selectedProduct == null) return 0;

      return this.availableSerialNumbers.length;
    },
    getProductMissingAmount() {
      if (this.selectedProduct == null) return 0;

      let loadedAmount = this.getVehicleLoadedAmount();
      let neededAmount = 0;

      for (let i = 0; i < this.items.length; i++) {
        neededAmount += parseInt(this.items[i].optimalQuantity);
      }
      let excess = neededAmount - loadedAmount;
      return excess;
    },
    getTotalAmount() {
      if (this.selectedProduct == null) return 0;

      let totalAmount = 0;
      if (this.products.length === 0 || this.selectedProduct === "") return totalAmount;
      if (this.products.find(x => x.id == this.selectedProduct).hasSerialNumber)
        totalAmount += this.getStockProductAmount();
      totalAmount += this.getVehicleLoadedAmount();

      return totalAmount;
    },
    autocompleteItems(row) {
      return this.availableSerialNumbers
        .filter(x => x.indexOf(this.tag[row.vehicleId]) !== -1)
        .map(x => {
          return { text: x };
        });
    },
    tags(vehicle) {
      return vehicle.serialNumbers.map(x => {
        return { text: x };
      });
    },
    changeTags(vehicle, newValue) {
      vehicle.serialNumbers = newValue.map(x => x.text);
    },
    changeLoadedQuantity(row) {
      let initialValue = this.initialLoadedValues.find(x => x.vehicleId == row.vehicleId);

      row.additionalQuantity = row.loadedQuantity - initialValue.loadedQuantity;
    },
    changeAdditionalQuantity(row) {
      let initialValue = this.initialLoadedValues.find(x => x.vehicleId == row.vehicleId);

      row.loadedQuantity = initialValue.loadedQuantity + row.additionalQuantity;
    },
    getAllGoods() {
      this["goods/getBaseInfoList"]().then(resp => {
        this.products = resp.data;
      });
    },
    calcAmounts() {
      this.vehicleLoadedAmount = this.getVehicleLoadedAmount();
      this.productMissingAmount = this.getProductMissingAmount();
      this.totalAmount = this.getTotalAmount();
      this.stockProductAmount = this.getStockProductAmount();
    },
    clearAmounts() {
      this.vehicleLoadedAmount = "";
      this.stockProductAmount = "";
      this.productMissingAmount = "";
      this.totalAmount = "";
    },
    getAugment() {
      if (this.selectedProduct == null) {
        this.items = [];
        this.clearAmounts();

        return;
      }

      const params = {
        ...this.filter,
        ...this.paginationParams,
        loadDate: this.filter.loadDate ? this.filter.loadDate.toISOString() : null
      };

      this.$store.dispatch("goods/getGoodsAugment", { goodsId: this.selectedProduct, params }).then(resp => {
        this.items = resp.data.items;
        this.pagination.totalCount = resp.data.totalCount;
        this.initialLoadedValues = this.items.map(x => {
          return {
            vehicleId: x.vehicleId,
            loadedQuantity: x.loadedQuantity
          };
        });

        if (this.products.find(x => x.id == this.selectedProduct).hasSerialNumber) {
          this["goods/getAvailableSerialNumbers"](this.selectedProduct).then(resp => {
            this.availableSerialNumbers = resp.data.serialNumbers;
            this.calcAmounts();
          });
          this.selectedProductHasSerialNumber = true;
        } else {
          this.selectedProductHasSerialNumber = false;
          this.calcAmounts();
        }
      });
    },
    getGoodsByGroup() {
      if (this.selectedGroup != null) {
        this["goods/getGoodsByGroup"]({ groupTitle: this.selectedGroup, locale: this.$i18n.locale }).then(resp => {
          this.products = resp.data;
          this.selectedProduct = null;
          this.getAugment();
        });
      } else {
        this.getAllGoods();
        this.selectedProduct = null;
        this.getAugment();
      }
    },
    equipCar(vehicle) {
      if (!this.products.find(x => x.id == this.selectedProduct).hasSerialNumber) {
        if (vehicle.loadedQuantity >= 0) {
          let vmWithoutSerial = {
            vehicleId: vehicle.vehicleId,
            itemToChange: { count: parseFloat(vehicle.loadedQuantity), goodsId: this.selectedProduct }
          };
          this.uploadChangedData(vmWithoutSerial);
        } else {
          alert(this.$t("goodsLoadingGroup.exception"));
        }
      } else {
        if (vehicle.loadedQuantity >= 0 && vehicle.serialNumbers.length == vehicle.loadedQuantity) {
          let vmWithSerial = {
            vehicleId: vehicle.vehicleId,
            itemToChange: {
              goodsId: this.selectedProduct,
              serialNumbers: vehicle.serialNumbers
            }
          };
          this.uploadChangedData(vmWithSerial);
        } else {
          alert(this.$t("goodsLoadingGroup.serialNumbersException"));
        }
      }
    },
    uploadChangedData(vm) {
      api
        .changeLoadItem(vm)
        .then(() => {
          this.getAugment();
        })
        .catch(err => {
          if (err.data.errorCode) {
            alert(this.$t("vehicle-load.error." + err.data.errorCode, err.data.additionalData));
          } else {
            alert(this.$t("vehicle-load.error.error-while-uploading-vehicle-load"));
          }
        });
    }
  },
  mounted() {
    this["goodsGroup/getGroupsInfo"]().then(resp => {
      this.groups = resp.data;
      this.getAllGoods();
    });
  }
};
</script>
